import { MIXPANEL_DEV_TOKEN, MIXPANEL_PROD_TOKEN } from './config';
import { reaction, makeAutoObservable, runInAction } from 'mobx';
import TagManager from 'react-gtm-module';
import { navigate } from 'takeme';
import { component, initialize } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import mixpanel from 'mixpanel-browser';
import { Option } from '../components/select';
import { Api } from './api';
import { ConfigurationDto, I18nText } from './api/dtos';
import { links } from './router';
import { TenantStore } from './tenant';
import { injectTSDI } from './tsdi';
import { Url } from './url';
import { LanguageOption, TLanguage } from '../components/language-select';
import { getLanguageTranslation } from './enums';
import { I18n, getLanguageKey } from './i18n';

@component
export class UserStore {
    private get api() {
        return injectTSDI(Api);
    }
    private get __() {
        return injectTSDI(I18n).__;
    }

    private get url() {
        return injectTSDI(Url);
    }

    private get tenantStore() {
        return injectTSDI(TenantStore);
    }

    public userConfigData = wrapRequest(async () => {
        this.api.initialDataLoaded = false;
        const me = await this.api.client.CompanyAccountController.getMeInfo();
        const config =
            await this.api.client.ConfigurationController.getConfiguration();

        if (config) {
            this.api.setUserPermissionFeatures(config.enabledFeatures);
            this.api.setProductType(config.productType);
        }
        return { me, config };
    });

    public me = this.userConfigData.pipe((data) => data.me);

    public userConfig = this.userConfigData.pipe((data) => data.config);

    public company = wrapRequest((companyId: number) =>
        this.api.client.CompanyController.get(companyId)
    );

    public countries = wrapRequest(() =>
        this.api.client.CountryController.getAvailableCountries()
    );

    public currencies = wrapRequest(() =>
        this.api.client.CurrencyController.getCurrencies()
    );

    public currencyOptions = this.currencies.pipe<Option<string>[]>((res) =>
        res.map((currency) => ({
            label: `${currency.symbol} ${currency.label}`,
            value: currency.value
        }))
    );

    private supportSession = false;

    public get promoter() {
        return this.company.$;
    }
    public get getCurrencySymbol() {
        return this.company.$?.currency?.symbol || '€';
    }

    public get getCurrencyCode() {
        return this.company.$?.currency?.code || 'EUR';
    }

    public get getTimeZone() {
        return this.company.$?.timeZone || 'Europe/Berlin';
    }

    public get isAdmin() {
        return this.me.$?.role === 'ADMIN';
    }
    public get isManager() {
        return this.me.$?.role === 'MANAGER';
    }

    public get isExternalSupport() {
        return this.me.$?.role === 'EXTERNAL_SUPPORT';
    }

    public get promoterType() {
        return this.company.$?.promoterType ?? 'COMPANY';
    }

    public get canEdit() {
        return ['EXTERNAL_SUPPORT', 'ADMIN', 'MANAGER'].some(
            (role) => role === this.me.$?.role
        );
    }

    public get cyclingFeatEnabled(): boolean {
        return this.userConfig.$?.enabledFeatures?.includes('CYCLING') ?? false;
    }

    public get sponsorFeatEnabled(): boolean {
        return this.userConfig.$?.enabledFeatures?.includes('SPONSOR') ?? false;
    }

    public get organizerFeatEnabled(): boolean {
        return (
            this.userConfig.$?.enabledFeatures?.includes('ORGANIZER') ?? false
        );
    }

    public get manualCheckInFeatEnabled(): boolean {
        return (
            this.userConfig.$?.enabledFeatures?.includes('MANUAL_CHECK_IN') ??
            false
        );
    }

    public get groupsFeatEnabled(): boolean {
        return (
            this.userConfig.$?.enabledFeatures?.includes('EMPLOYEE_GROUPS') ??
            false
        );
    }

    public get tourGuideFeatEnabled(): boolean {
        return (
            this.userConfig.$?.enabledFeatures?.includes('TOUR_GUIDE') ?? false
        );
    }

    public get statsEnabled(): boolean {
        return this.userConfig.$?.enabledFeatures?.includes('STATS') ?? false;
    }

    public get liveDataStatsEnabled(): boolean {
        return (
            (this.isExternalSupport ||
                this.userConfig.$?.enabledFeatures?.includes(
                    'LIVE_DATA_STATS'
                )) ??
            false
        );
    }

    public get stepsToCyclingMetersMultiplier(): number {
        return this.userConfig.$?.stepsToCyclingMetersMultiplier ?? 2;
    }
    public get studioVisitPointsPerMinuteMultiplier(): number {
        return this.userConfig.$?.studioVisitPointsPerMinuteMultiplier ?? 100;
    }

    public get isTeamsPortal(): boolean {
        return this.productType === 'TEAMS';
    }
    public get isCorporatePortal(): boolean {
        return !this.isTeamsPortal;
    }

    public get productType(): ConfigurationDto['productType'] {
        return this.userConfig.$?.productType ?? 'TEAMS';
    }

    public get mainLanguageKey() {
        return this.userConfig.$?.localeCode
            ? getLanguageKey(this.userConfig.$?.localeCode)
            : 'en';
    }

    public get supportedLanguages(): TLanguage[] {
        const locales =
            ([...this.userConfig.$.supportedLocales] as TLanguage[]) || [];
        return locales.sort((key) =>
            (key as TLanguage) === this.mainLanguageKey ? -1 : 1
        );
    }

    public get supportedLanguageOptions(): LanguageOption[] {
        return this.supportedLanguages.map((language) => ({
            value: language,
            label: getLanguageTranslation(language),
            main: language === this.mainLanguageKey
        }));
    }

    public get allLanguageOptions(): Option[] {
        return [
            {
                value: 'en',
                label: this.__('language.en'),
                disabled: this.mainLanguageKey === 'en'
            },
            {
                value: 'de',
                label: this.__('language.de'),
                disabled: this.mainLanguageKey === 'de'
            },
            {
                value: 'pl',
                label: this.__('language.pl'),
                disabled: this.mainLanguageKey === 'pl'
            },
            {
                value: 'es',
                label: this.__('language.es'),
                disabled: this.mainLanguageKey === 'es'
            }
        ];
    }

    public get initialLanguageData(): I18nText {
        return this.supportedLanguages.reduce(
            (acc, current) => ({ ...acc, [current]: '' }),
            {}
        );
    }

    private async startSupportSession(
        supportAccessToken: string,
        tenant: string
    ) {
        try {
            runInAction(() => {
                this.supportSession = true;
            });
            await this.api.setSessionCookie(supportAccessToken, tenant);
            runInAction(() => {
                this.api.loggedIn = true;
                this.supportSession = false;
            });
            navigate(links.indexPrivate());
        } catch (e) {
            console.warn(e);
        }
    }

    private initGoogleTag() {
        if (this.url.env === 'prod') {
            TagManager.initialize({
                gtmId: 'GTM-PBSNPDCB'
            });
        }
    }

    private initMixpanel() {
        mixpanel.init(
            this.url.isDev ? MIXPANEL_DEV_TOKEN : MIXPANEL_PROD_TOKEN
        );
    }

    @initialize
    public init(): void {
        makeAutoObservable(this, {}, { autoBind: true });
        this.initGoogleTag();
        this.initMixpanel();
        const supportAccessToken = this.url.getQueryParam('supportAccessToken');
        const tenantParam = this.url.getQueryParam('tenant');

        if (supportAccessToken && tenantParam) {
            this.startSupportSession(supportAccessToken, tenantParam);
            this.api.persistXAuthToken(supportAccessToken);
        }

        reaction(
            () => ({
                me: this.me.$,
                isPrivatePath: this.api.isPrivatePath,
                loggedIn: this.api.loggedIn,
                tenant: this.tenantStore.tenant,
                supportSession: this.supportSession
            }),
            ({ me, isPrivatePath, loggedIn, tenant, supportSession }) => {
                if (
                    !me &&
                    !supportSession &&
                    isPrivatePath &&
                    loggedIn &&
                    tenant
                ) {
                    this.me.request();
                } else if (!tenant && loggedIn) {
                    loggedIn = false;
                }
            }
        );

        reaction(
            () => ({
                me: this.me.$,
                isPrivatePath: this.api.isPrivatePath
            }),
            async ({ me, isPrivatePath }) => {
                if (me && isPrivatePath) {
                    await this.company.request(me.companyId);
                    await this.countries.request();
                    this.api.initialDataLoaded = true;
                }
            }
        );

        reaction(
            () => ({ loggedIn: this.api.loggedIn, me: this.me }),
            ({ loggedIn, me }) => {
                if (me.fetched) {
                    if (!loggedIn && this.api.isPrivatePath) {
                        navigate(links.indexPublic());
                    }
                    if (!loggedIn) {
                        me.reset(undefined);
                    }
                }
            }
        );
    }
}
